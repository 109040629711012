import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Karol",
      desc: "“Great communication and express help with the execution of two projects. I sincerely recommend cooperation with Mr. Rafał!”",
    },
    {
      name: "Bartłomiej",
      desc: "“I used help with 2 projects: 1) MongoDB in Docker, 2) AI - Artificial Intelligence in Data Processing. 10/10 good communication, he took the time to explain, 10/10 response speed to inquiry, 10/10 project completion time (by the end of May!). I recommend!”",
    },
    {
      name: "Kacper",
      desc: "“Immediate help with tasks. Everything explained step by step in a clear and easy to understand way. Atmosphere is very friendly.”",
    },
    {
      name: "Agnieszka",
      desc: "“It seems that the content of the advertisement is consistent with the services provided, which doesn't happen often. A competent person in the right place, able to share knowledge and tips—in my case, it was a database schema project. I think the key is satisfaction, which will result in future cooperation when needed!”",
    },
    {
      name: "Paulina",
      desc: "“Contact with Mr. Rafał took place in a pleasant atmosphere. He replies immediately to messages. You can see that you're dealing with someone who knows what he's doing. Professional approach to the task, and the results are clearly visible. I highly recommend—in my case, Mr. Rafał helped with an Excel project. Thank you very much.”",
    },
    {
      name: "Dariusz",
      desc: "“What I pay special attention to is quick and hassle-free communication, and that was not lacking here. Mr. Rafał showed a very human approach, which is sometimes missing, especially in the IT industry. I recommend!”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Client Speak</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          What Some of my Clients Say
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
