import "./App.scss";
import WOW from "wowjs";
import AboutUs from "./components/About";
import Services from "./components/Services";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import {
  appliedConfig,
  introBackgroundConfig,
  themeConfig,
} from "./config/commonConfig";
import { Tooltip } from "./components/Tooltip";
import CallToAction from "./components/CallToAction";
import FAQs from "./components/FAQs";
import FullScreenHeader from "./components/themes/fullScreen/Header";
import FullScreenDefaultIntro from "./components/themes/fullScreen/IntroDefault";
import MySkills from "./components/MySkills";
import DownloadCV from "./components/DownloadCV";
import Testimonials from "./components/Testimonials";

function App() {
  const appliedTheme = appliedConfig.appliedTheme;
  const appliedIntro = appliedConfig.appliedIntro;

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  const getHeader = () => {
    if (appliedTheme === themeConfig.BottomHeader) {
      return <FullScreenHeader
          textWhite={appliedIntro === introBackgroundConfig.image}
      ></FullScreenHeader>;
    } else if (appliedTheme === themeConfig.FullScreenMenu) {
      return (
        <FullScreenHeader
          textWhite={appliedIntro === introBackgroundConfig.image}
        ></FullScreenHeader>
      );
    }
  };

  const getFullScreenIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <FullScreenDefaultIntro></FullScreenDefaultIntro>;
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div id="main-wrapper">
          {getHeader()}

          <div id="content" role="main">
            {appliedTheme === themeConfig.FullScreenMenu &&
              getFullScreenIntro()}

            <AboutUs></AboutUs>
            <Services></Services>
            <Resume></Resume>
            <MySkills></MySkills>
            <DownloadCV></DownloadCV>
            {/*<Portfolio></Portfolio>*/}
            <CallToAction></CallToAction>
            <FAQs></FAQs>
            <Testimonials></Testimonials>
            <Contact></Contact>
          </div>
          <Footer handleNavClick={handleNavClick}></Footer>
        </div>
        {/* back to top */}
        <Tooltip text="Back to Top" placement="left">
          <span
            id="back-to-top"
            className="rounded-circle"
            style={{ display: scrollTopVisible ? "inline" : "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <i className="fas fa-arrow-up"></i>
          </span>
        </Tooltip>
      </div>
    </>
  );
}

export default App;
