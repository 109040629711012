import React from 'react';
import resumeFile from '../documents/resume_rafal_fuchs.pdf';

const DownloadCV = () => {
    return (
        <section id="downloadResume" className="section">
            <div className="container">
                {/* Heading */}
                <p className="text-center mb-2 wow fadeInUp">
                    <span className="bg-primary text-dark px-2">PDF Resume</span>
                </p>
                <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
                    A summary of My Resume
                </h2>
                {/* Heading end*/}
                {/* Content */}
                <div className="text-center mt-5 wow fadeInUp">
                    <p className="mb-3">
                        If you need my CV in PDF format, you can download it below.
                    </p>
                    <a
                        className="btn btn-primary shadow-none rounded-0"
                        href={resumeFile}
                        download
                    >
                        Download CV
                    </a>
                </div>
            </div>
        </section>
    );
};

export default DownloadCV;
