import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "What services do you offer?",
      answer:
          "I offer backend development services specializing in Python and Django, including custom backend development, database refactoring, performance optimization, and end-to-end project implementation.",
    },
    {
      question: "How can you help optimize my web application?",
      answer:
          "I can analyze your current web application's performance and implement optimizations to improve speed, scalability, and user experience, ensuring your application runs efficiently.",
    },
    {
      question: "What is your experience with data migration?",
      answer:
          "I have extensive experience in database refactoring and critical data migrations using Django ORM, ensuring data integrity and minimal downtime during transitions.",
    },
    {
      question: "Do you provide automated testing?",
      answer:
          "Yes, I implement automated testing as a standard part of my development process to ensure reliability and that all solutions meet specified business requirements.",
    },
    {
      question: "How can I help your business grow?",
      answer:
          "By aligning technical solutions with your business objectives, I create scalable and efficient systems that drive growth and enhance operational efficiency.",
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-2">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Have any questions?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
