import React from "react";

const Skills = () => {
  const skills = [
    {
      category: "Backend",
      technologies: [
        { name: "Python", image: "images/skills/python.png" },
        { name: "Django", image: "images/skills/django.png" },
        { name: "DRF", image: "images/skills/drf.png" },
        { name: "GraphQL", image: "images/skills/graph.png" },
        { name: "Celery", image: "images/skills/celery.png" },
        { name: "Redis", image: "images/skills/redis.png" },
      ],
    },
    {
      category: "Frontend",
      technologies: [
        { name: "React", image: "images/skills/react.png" },
        { name: "JavaScript", image: "images/skills/js.png" },
        { name: "TypeScript", image: "images/skills/ts.png" },
        { name: "HTML5", image: "images/skills/html.png" },
        { name: "CSS", image: "images/skills/css.png" },
        { name: "SASS", image: "images/skills/sass.png" },
      ],
    },
    {
      category: "Databases",
      technologies: [
        { name: "PostgreSQL", image: "images/skills/postgresql.png" },
        { name: "MySQL", image: "images/skills/mysql.png" },
        { name: "SQLite", image: "images/skills/sqlite.png" },
        { name: "MongoDB", image: "images/skills/mongo.png" },
      ],
    },
    {
      category: "Tools & Others",
      technologies: [
        { name: "Git", image: "images/skills/git.png" },
        { name: "Docker", image: "images/skills/docker.png" },
        { name: "AWS", image: "images/skills/aws.png" },
        { name: "GCP", image: "images/skills/gcp.png" },
        { name: "Ansible", image: "images/skills/ansible.png" },
        { name: "Grafana", image: "images/skills/grafana.png" },
        { name: "Kubernetes", image: "images/skills/kubernetes.png" },
        { name: "Terraform", image: "images/skills/terraform.png" },
        { name: "Nginx", image: "images/skills/nginx.png" },
      ],
    },
  ];

  return (
      <section id="skills" className="section bg-light">
        <div className="container">
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">My Skills</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Technologies I work with
          </h2>
          {skills.map((skillCategory, index) => (
              <div className="mb-5 wow fadeInUp" key={index}>
                <h3 className="text-5 fw-600 mb-4">{skillCategory.category}</h3>
                <div className="row gy-4">
                  {skillCategory.technologies.map((tech, idx) => (
                      <div
                          className="col-6 col-sm-4 col-md-3 col-lg-2 text-center"
                          key={idx}
                      >
                        <div className="featured-box">
                          <div className="featured-box-icon">
                            <img
                                src={tech.image}
                                alt={tech.name}
                                className="img-fluid"
                                style={{maxHeight: '60px'}}
                            />
                          </div>
                          <h4 className="text-4 mt-2">{tech.name}</h4>
                        </div>
                      </div>
                  ))}
                </div>
              </div>
          ))}
        </div>
      </section>
  );
};

export default Skills;
